import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import eventReducer from './eventReducer';
import wisataReducer from './wisataReducer';
import homestayReducer from './homestayReducer';

const rootReducer = combineReducers({
  session: sessionReducer, 
  event: eventReducer, 
  wisata: wisataReducer,
  homestay: homestayReducer
});

export default rootReducer;
