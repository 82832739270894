import { WISATA } from 'actions/types'

const intialState = {
    data: [], 
    detail: {}
}

export default function (state = intialState, action){
    switch(action.type){
        case `${WISATA}_DATA`:
            return {...state, data: action.payload}
        case `${WISATA}_DETAIL`: 
            return {...state, detail: action.payload}
        default:
            return state
    }
}