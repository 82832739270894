export const EVENT = "EVENT"
export const HOMESTAY = "HOMESTAY"
export const WISATA = "WISATA"
export const SESSION_LOGIN = "SESSION_LOGIN"
export const SESSION_LOGOUT = "SESSION_LOGOUT"
export const NOTIF = "NOTIF"

export const createAction = (type, actionProps) => {
    return {
        type,
        ...actionProps
    };
};