/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Errors/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Errors/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Errors/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('views/Home'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/changelog',
        exact: true,
        component: lazy(() => import('views/Changelog'))
      },
      {
        path: '/chat',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/transaksi',
        exact: true,
        component: lazy(() => import('views/Transaksi'))
      },
      {
        path: '/transaksi/detail/:id',
        exact: true,
        component: lazy(() => import('views/Transaksi/TransaksiDetail'))
      },
      {
        path: '/management/events',
        exact: true,
        component: lazy(() => import('views/Events/EventList'))
      },
      {
        path: '/management/events/tamu',
        exact: true,
        component: lazy(() => import('views/Events/EventTamu'))
      },
      {
        path: '/management/events/create',
        exact: true,
        component: lazy(() => import('views/Events/EventCreate'))
      },
      {
        path: '/management/events/detail/:id',
        exact: true,
        component: lazy(() => import('views/Events/EventDetail'))
      },
      {
        path: '/management/events/:id/:tab',
        exact: true,
        component: lazy(() => import('views/Events/EventList'))
      },
      {
        path: '/management/wisata',
        exact: true,
        component: lazy(() => import('views/Wisata/WisataList'))
      },
      {
        path: '/management/wisata/create',
        exact: true,
        component: lazy(() => import('views/Wisata/WisataCreate'))
      },
      {
        path: '/management/wisata/tamu',
        exact: true,
        component: lazy(() => import('views/Wisata/WisataTamu'))
      },
      {
        path: '/management/wisata/detail/:id',
        exact: true,
        component: lazy(() => import('views/Wisata/WisataDetail'))
      },
      {
        path: '/management/homestay',
        exact: true,
        component: lazy(() => import('views/Homestay/HomestayList'))
      },
      {
        path: '/management/homestay/create',
        exact: true,
        component: lazy(() => import('views/Homestay/HomestayCreate'))
      },
      {
        path: '/management/homestay/tamu',
        exact: true,
        component: lazy(() => import('views/Homestay/HomestayList'))
      },
      {
        path: '/management/homestay/detail/:id',
        exact: true,
        component: lazy(() => import('views/Homestay/HomestayDetail'))
      },
      {
        path: '/customer',
        exact: true,
        component: lazy(() => import('views/Customer/CostumerList'))
      },
      {
        path: '/customer/detail/:id',
        exact: true,
        component: lazy(() => import('views/Customer/CustomerDetail'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
