import { createAction, WISATA, NOTIF } from './types'
import * as service from 'service'
import { baseUrl } from 'config'
import axios from 'axios'

export const createWisata = (data) => dispatch => {
    let formData = new FormData()
    formData.append('image', data.image)
    formData.append('data', JSON.stringify(data))
    try {
        axios({
            url: baseUrl + '/wisata',
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onDownloadProgress: progres => {
                // dispatch(createAction(`${NOTIF}_INFO`, { payload: 'Uploading' }))
            }
        }).then(res => {
            const response = res.data
            if (!response.status) {
                dispatch(createAction(`${NOTIF}_ERROR`, { payload: response.message }))
            } else {
                dispatch(createAction(`${NOTIF}_INFO`, { payload: response.message }))
                dispatch(getWisata())
            }
        })
    } catch (e) {
        console.log(e)
    }

    // formPayload.append('images', data.image)
    // formPayload.append('data', JSON.stringify(data))
    // console.log(data.image)

}

export const getWisata = () => dispatch => {
    service.get('/wisata').then(res => {
        dispatch(createAction(`${WISATA}_DATA`, { payload: res.data }))
    })
}

export const getDetailWisata = (id) => dispatch => {
    service.get(`/wisata/${id}`).then(res => {
        dispatch(createAction(`${WISATA}_DETAIL`, { payload: res.data }))
    })
}

export const editWisata = (id, data) => dispatch => {

}

export const deleteWisata = (id) => dispatch => {
    service.del(`/wisata/${id}`).then(res => {
        console.log(res)
        dispatch(createAction(`${NOTIF}_INFO`, { payload: res.message }))
        dispatch(getWisata())
    })
}