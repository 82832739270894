const production = {
    url: 'https://travel.ratpoisonsale.com'
}

const development = {
    url: 'http://localhost/ranupane/service'
}

const api = process.env.NODE_ENV === 'production' ? production : development;

module.exports = {
    baseUrl: api.url
}