import React, { createContext } from 'react'
import { firebaseConfig } from 'config/firebase'

import firebase from 'firebase/app'
import 'firebase/remote-config'
import 'firebase/performance'
import 'firebase/auth'
import 'firebase/analytics'

const FIrebaseContext = createContext()

firebase.initializeApp(firebaseConfig)
// const remoteConfig = firebase.remoteConfig()

// console.log(remoteConfig)
const FirebaseInit = props => {
    // const [flags, setFlag] = useState({
    //     baseUrl: 'http://localhost/ranupane/api'
    // })

    // useEffect(() => {
    //     remoteConfig.defaultConfig = flags

    //     remoteConfig.fetchAndActivate()
    //         .then(activated => {
    //             if (!activated) console.log('not activated');
    //             return remoteConfig.getAll()
    //         })
    //         .then(remoteFlags => {
    //             console.log(remoteFlags)
    //         })
    // }, [flags])

    return <FIrebaseContext.Provider >{props.children}</FIrebaseContext.Provider>
}

export default FirebaseInit