import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import {
    Link,
    Typography,
    colors
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {},
    dropZone: {
        border: `1px dashed ${theme.palette.divider}`,
        padding: theme.spacing(1),
        outline: 'none',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: colors.grey[50],
            opacity: 0.5,
            cursor: 'pointer'
        }
    },
    dragActive: {
        backgroundColor: colors.grey[50],
        opacity: 0.5
    },
    imagePreview: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        // width: '100%',
        maxHeight: 320,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        '&:before': {
            position: 'absolute',
            content: '" "',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundImage:
                'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
        },
    },
    image: {
        width: 130
    },
    info: {
        marginTop: theme.spacing(1)
    },
    list: {
        maxHeight: 320
    },
    actions: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        '& > * + *': {
            marginLeft: theme.spacing(2)
        }
    }
}));


const ImageDropzone = props => {
    const { className, uploadImage, ...rest } = props
    const classes = useStyles()
    const [image, setImage] = useState(null)
    const handleDrop = useCallback(acceptFiles => {
        uploadImage(acceptFiles)
        setImage(URL.createObjectURL(acceptFiles[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop
    });

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            {image ? <div className={clsx({
                [classes.dropZone]: true, 
                [classes.dragActive]: isDragActive
            })} {...getRootProps()}>
                <input {...getInputProps()}/>
                <img alt="photodata" className={classes.imagePreview} src={image} />
            </div>

                : <div className={clsx({
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive
                })} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div>
                        <img
                            alt="Select file"
                            className={classes.image}
                            src="/images/undraw_add_file2_gvbb.svg"
                        />
                    </div>
                    <div>
                        <Typography
                            gutterBottom
                            variant="h3">
                            Select files
                    </Typography>
                        <Typography
                            className={classes.info}
                            color="textSecondary"
                            variant="body1">
                            Drop files here or click <Link underline="always">browse</Link>{' '}
                        thorough your machine
                    </Typography>
                    </div>
                </div>}
        </div>
    )

}

ImageDropzone.propTypes = {
    className: PropTypes.string, 
    uploadImage: PropTypes.func
}

export default ImageDropzone