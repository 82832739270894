import { SESSION_LOGIN, SESSION_LOGOUT, NOTIF } from 'actions/types';
import cogoToast from 'cogo-toast'

const initialState = {
  loggedIn: true,
  user: {
    first_name: 'Shen',
    last_name: 'Zhi',
    email: 'demo@devias.io',
    avatar: '/images/avatars/avatar_11.png',
    bio: 'Brain Director',
    role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
  }
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_LOGIN: {
      return {
        ...initialState
      };
    }

    case SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST'
        }
      };
    }

    case `${NOTIF}_SUCCESS`:
      cogoToast.success(action.payload, { position: 'top-right0' })
      return { ...state }
    case `${NOTIF}_ERROR`:
      cogoToast.error(action.payload, { position: 'top-right' })
      return { ...state }
    case `${NOTIF}_INFO`:
      cogoToast.info(action.payload, { position: 'top-right' })
      return { ...state }
    default: {
      return state;
    }
  }
};

export default sessionReducer;
