import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Card, CardMedia, Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {},
    media: {
        height: 150
    }
}))

const CardImage = props => {
    const { className, children, images, ...rest } = props
    const classes = useStyles()

    return (
        <Card {...rest} className={clsx(classes.root, className)} elevation={2}>
            {images ? <CardMedia className={classes.media} image={images} /> : null}
            <Divider />
            {children}
        </Card>
    )

}

CardImage.propTypes = {
    className: PropTypes.string,
    images: PropTypes.string,
    children: PropTypes.node
}

export default CardImage