import { EVENT, createAction, NOTIF } from './types'
import * as service from 'service'

export const createEvent = (data) => dispatch => {
    service.post('/event', data).then(res => {
        console.log(res)
    })
}

export const getEventData = () => dispatch => {
    service.get('/event').then(res => {
        dispatch({
            type: `${EVENT}_DATA`,
            payload: res.data
        })
    })
}

export const getDetailEvent = (id) => dispatch => {
    service.get(`/event/${id}`).then(res => {
        dispatch({
            type: `${EVENT}_DETAIL`,
            payload: res.data
        })
    })
}

export const editEvent = (id, data) => dispatch => {

}

export const removeEvent = (id) => dispatch => {
    service.del(`/event/${id}`).then(res => {
        dispatch(createAction(`${NOTIF}_INFO`, {payload: res.message}))
        dispatch(getEventData())
    })
}